import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as Api from "@/api";
import CommonConfirmModal, {
  ModalConfig,
} from "@/components/Common/CommonConfirmModal";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import ControlPanel from "@/components/Common/ControlPanel";
import CustomTableContainerTypes from "@/components/Common/CustomTableContainerTypes";
import DeleteModal from "@/components/Common/DeleteModal";
import ModalTypes from "@/components/Common/ModalTypes";
import PageContainer from "@/components/Common/PageContainer";
import UserFinderModal from "@/components/Common/UserFinderModal";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";
import useQueryParams, {
  useLocalizedMessage,
  useStateRef,
} from "@/helpers/hooks";
import { CommonApplicationItem } from "@/utils/api/features/Common/types";
import {
  AdminProfileToUserProfileReq,
  GetPrintingProfileList,
  MigrationResult,
  PrintingProfileItem,
} from "@/utils/api/features/PrintingProfile/types";
import resultTable, { GetColumns, handleDeleteProfile } from "./utils/index";

import { isProd } from "../../utils/isProd.js";
import usePrintingProfileStore from "../../zustandStore/printingProfilesStore";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Printing Profile", link: "#" },
  { title: "List", link: "#" },
];

type PrintingProfileProps = {
  isModal: boolean;
  onChange: (selectedProfiles: Api.Response.PrintingProfileItem[]) => void;
};

const PrintingProfile = ({ isModal, onChange }: PrintingProfileProps) => {
  const { profiles, setProfiles, loading, setLoading } =
    usePrintingProfileStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const queryParams = queryString.parse(location.search);
  const { parseQueryParam } = useQueryParams();

  const [customPageSize, setCustomPageSize] = useState(
    isModal ? 5 : parseInt(queryParams.pageSize as string) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page as string) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalProfiles, setTotalProfiles] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const searchKeywordRef = useStateRef(searchKeyword);
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [applicationTypes, setApplicationTypes] = useState<
    CommonApplicationItem[]
  >([]);
  const [selectedAppType, setSelectedAppType] = useState(
    Number(queryParams.appType) || 0,
  );
  const [selectedRegType, setSelectedRegType] = useState(() => {
    const parsedRegType = parseQueryParam(queryParams.regType);
    return parsedRegType !== null
      ? parsedRegType
      : REGISTRATION_OPTIONS[0].value;
  });
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);
  const [selectedProfileIds, setSelectedProfileIds] = useState<number[]>([]);
  const [copyResult, setCopyResult] = useState<MigrationResult[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [isUserFinderOpen, setIsUserFinderOpen] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState<PrintingProfileItem>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const closeModal = () => {
    setModalConfig((prev) => ({ ...prev, isOpen: false }));
  };

  const handleRowClick = (item: PrintingProfileItem) => {
    navigate(`/printing-profile/${item.printingIdx}${window.location.search}`);
  };

  const handleAddProfile = () => {
    navigate(`/printing-profile/new${window.location.search}`);
  };

  const handleUserSelection = (selectedUsers: number[]) => {
    setModalConfig({
      isOpen: true,
      title: "선택한 프로파일을 사용자화 하시겠습니까?",
      subTitle: "잘못된 정보가 없는지 주의해주세요.",
      onConfirm: async () => {
        setLoading(true);
        try {
          const requestData: AdminProfileToUserProfileReq = {
            printingIdx: selectedProfileIds[0],
            userIdx: selectedUsers[0],
          };

          const response = await Api.PrintingProfile.toUserProfile(requestData);
          const successMessage = getLocalizedMessage(
            response,
            DEFAULT_SUCCESS_MESSAGE,
          );
          toast.success(successMessage, { autoClose: 3000 });
          fetchProfiles();
        } catch (error) {
          console.error("Error deleting profile:", error);
          const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
          toast.error(errMessage, {
            autoClose: 3000,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  useEffect(() => {
    if (isModal) {
      clearFilters();
    }
  }, [isModal]);

  useEffect(() => {
    if (!isModal) {
      const params = {
        page: currentPage,
        pageSize: customPageSize,
        keyword: searchKeyword,
        startDt: dateRange[0],
        endDt: dateRange[1],
        appType: selectedAppType,
        regType: selectedRegType,
        show: selectedShow,
      };
      navigate(`${location.pathname}?${queryString.stringify(params)}`);
    }
  }, [
    isModal,
    currentPage,
    customPageSize,
    searchKeyword,
    dateRange,
    selectedAppType,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: GetPrintingProfileList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        applicationsType: selectedAppType,
        startDt: dateRange[0]
          ? format(dateRange[0] as string, "yyyy-MM-dd")
          : "",
        endDt: dateRange[1] ? format(dateRange[1] as string, "yyyy-MM-dd") : "",
      };

      const response = await Api.PrintingProfile.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalProfiles(response.paging.totalRecord);
      setProfiles(response.data);
    } catch (error) {
      console.error("Error fetching Printing Profiles", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedAppType,
    selectedRegType,
    selectedShow,
    dateRange[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  useEffect(() => {
    const fetchApplicationTypes = async () => {
      const response = await Api.Common.getApplicationList();
      const applications = response.data;
      const updatedApplications = [
        {
          name: "전체",
          imgPath: "",
          applicationsIdx: 0,
        },
        ...applications,
      ];

      setApplicationTypes(updatedApplications);
    };
    fetchApplicationTypes();
  }, []);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchProfiles();
  };

  const handleAppTypeSearch = (newAppType: number) => {
    setSelectedAppType(newAppType);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType: number) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow: number) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: string[]) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange(["", ""]);
    setSelectedRegType(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setSelectedAppType(0);
    setClearTrigger((prev) => !prev);
  };

  const handleCheckboxChange = useCallback(
    (printingIdx: number) => {
      setSelectedProfileIds((prev) =>
        prev.includes(printingIdx)
          ? prev.filter((id) => id !== printingIdx)
          : [...prev, printingIdx],
      );

      if (isModal) {
        const selectedProfile = profiles.find(
          (profile: Api.Response.PrintingProfileItem) =>
            profile.printingIdx === printingIdx,
        );

        if (selectedProfile) {
          onChange([selectedProfile]);
        }
      }
    },
    [profiles],
  );

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedProfileIds(
          profiles.map((profile: PrintingProfileItem) => profile.printingIdx),
        );
      } else {
        setSelectedProfileIds([]);
      }
    },
    [profiles],
  );

  const openModal = () => {
    if (!selectedProfileIds.length) {
      toast.error("먼저 프린팅 프로파일을 선택해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setAction("copy");
    setIsOpen(true);
  };

  const openUserFinder = () => {
    if (!selectedProfileIds.length) {
      toast.error("먼저 프린팅 프로파일을 선택해주세요", {
        autoClose: 3000,
      });

      return;
    }

    if (selectedProfileIds.length > 1) {
      toast.error("한개의 프로파일만 선택해주세요", {
        autoClose: 3000,
      });

      return;
    }

    setIsUserFinderOpen(true);
  };

  const handleCopyForMigration = async () => {
    setLoading(true);
    try {
      const requestData = {
        idx: selectedProfileIds.join(","),
      };
      const response = await Api.PrintingProfile.copyForMigration(requestData);
      setCopyResult(response.data.result);
      setIsModalOpen(true);
      setSelectedProfileIds([]);
    } catch (error) {
      console.error("Error: copy Printing Profile", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleIsModalOpen = (item: PrintingProfileItem) => {
    setProfileToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!profileToDelete?.printingIdx) return;
    const printingIdx = profileToDelete?.printingIdx;
    await handleDeleteProfile({
      printingIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsDeleteModalOpen(false);
    fetchProfiles();
  };

  const columns = GetColumns({
    handleRowClick,
    handleIsModalOpen,
    isModal,
  });

  return (
    <>
      {isModal ? (
        <>
          <ControlPanel
            placeholder="프로파일명, 프린터명, 소재명, 제조사명, 등록자명 등으로 검색해주세요."
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            clearFilters={clearFilters}
            onSearch={handleSearch}
            dateRange={dateRange}
            setDateRange={handleDateSearch}
            options0={applicationTypes}
            selectedOption0={selectedAppType}
            setSelectedOption0={handleAppTypeSearch}
            options1={REGISTRATION_OPTIONS}
            options2={SHOW_OPTIONS}
            selectedOption1={selectedRegType}
            setSelectedOption1={handleRegTypeSearch}
            selectedOption2={selectedShow}
            setSelectedOption2={handleShowSearch}
            selectTitle0="구분"
            selectTitle1="기본프로파일여부"
            selectTitle2="표시여부"
            isSingleSelect={false}
          >
            {""}
          </ControlPanel>
          <CustomTableContainerTypes
            btnTitle={!isModal ? "프로파일 등록" : ""}
            btnTitle1={
              !isModal ? (isProd ? "스테이징 복사" : "프로덕션 복사") : ""
            }
            btnIcon1="mdi mdi-content-copy me-2"
            handleBtn1={openModal}
            btnTitle2={!isModal ? "사용자지정" : ""}
            btnIcon2="ri-user-line me-2"
            handleBtn2={openUserFinder}
            sortByIdx="printingIdx"
            handleAddItem={handleAddProfile}
            columns={columns || []}
            data={profiles || []}
            customPageSize={customPageSize}
            totalPage={totalPage}
            totalRecord={totalProfiles}
            setCustomPageSize={setCustomPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={loading}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            selectedIds={selectedProfileIds}
            handleCheckboxChange={handleCheckboxChange}
            handleCheckAll={handleCheckAll}
            showCheckboxColumn
            isModal
          />
        </>
      ) : (
        <PageContainer
          breadcrumbItems={BREADCRUMB_ITEMS}
          title="Printing Profile"
        >
          <ControlPanel
            placeholder="프로파일명, 프린터명, 소재명, 제조사명, 등록자명 등으로 검색해주세요."
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            clearFilters={clearFilters}
            onSearch={handleSearch}
            dateRange={dateRange}
            setDateRange={handleDateSearch}
            options0={applicationTypes}
            selectedOption0={selectedAppType}
            setSelectedOption0={handleAppTypeSearch}
            options1={REGISTRATION_OPTIONS}
            options2={SHOW_OPTIONS}
            selectedOption1={selectedRegType}
            setSelectedOption1={handleRegTypeSearch}
            selectedOption2={selectedShow}
            setSelectedOption2={handleShowSearch}
            selectTitle0="구분"
            selectTitle1="기본프로파일여부"
            selectTitle2="표시여부"
            isSingleSelect={false}
          >
            {""}
          </ControlPanel>
          <CustomTableContainerTypes
            btnTitle="프로파일 등록"
            btnTitle1={isProd ? "스테이징 복사" : "프로덕션 복사"}
            btnIcon1="mdi mdi-content-copy me-2"
            handleBtn1={openModal}
            btnTitle2="사용자지정"
            btnIcon2="ri-user-line me-2"
            handleBtn2={openUserFinder}
            sortByIdx="printingIdx"
            handleAddItem={handleAddProfile}
            columns={columns || []}
            data={profiles || []}
            customPageSize={customPageSize}
            totalPage={totalPage}
            totalRecord={totalProfiles}
            setCustomPageSize={setCustomPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={loading}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            selectedIds={selectedProfileIds}
            handleCheckboxChange={handleCheckboxChange}
            handleCheckAll={handleCheckAll}
            showCheckboxColumn
          />
          <ConfirmModalTypes
            isLoading={loading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            action={action}
            handleCopy={handleCopyForMigration}
          />
          <ModalTypes
            title="복사 결과"
            isLoading={loading}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleSave={() => setIsModalOpen(false)} // 또는 true로 설정
            isSingleBtn
          >
            {resultTable(copyResult)}
          </ModalTypes>
          <DeleteModal
            isLoading={loading}
            data={profileToDelete?.profileName}
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            onHandleDelete={handleDelete}
          />
          <UserFinderModal
            isOpen={isUserFinderOpen}
            setIsOpen={setIsUserFinderOpen}
            isMultiple={false}
            onSelectComplete={handleUserSelection}
          />
          <ToastContainer />

          <CommonConfirmModal config={modalConfig} setIsOpen={closeModal} />
        </PageContainer>
      )}
    </>
  );
};

export default PrintingProfile;
