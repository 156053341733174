import * as Api from "@/api";
let requiredFields = [
  "profileName",
  "thickVal",
  "materialIdx",
  "printerIdx",
  "classIdx",
  // "displayFlag",
  "smoothingPixels",
  "inlineTolerance",
  "outlineTolerance",
  // "useAntiAliasing",
  // "useBottomGrayOffset",
  "scaleX",
  "scaleY",
  "scaleZ",
  "bottomGrayOffset",
  "bottomGrayLevel",
  "bottomLayerCount",
  "transitionLayerCount",
  "bottomLightPower",
  "lightPower",
  "bottomExposureTime",
  "exposureTime",
  // "waitingMode",
  "bottomLightOffDelay",
  "lightOffDelay",
  "restTimeBeforeLift",
  "restTimeAfterLift",
  "restTimeAfterRetract",
  "bottomLiftHeight1",
  "bottomLiftHeight2",
  "bottomRetractHeight1",
  "bottomRetractHeight2",
  "liftHeight1",
  "liftHeight2",
  "retractHeight1",
  "retractHeight2",
  "bottomLiftSpeed1",
  "bottomLiftSpeed2",
  "bottomRetractSpeed1",
  "bottomRetractSpeed2",
  "liftSpeed1",
  "liftSpeed2",
  "retractSpeed1",
  "retractSpeed2",
  // "useTrapControl",
  "trapLiftHeight1",
  "trapLiftHeight2",
  "trapRetractHeight1",
  "trapRetractHeight2",
  "trapLiftSpeed1",
  "trapLiftSpeed2",
  "trapRetractSpeed1",
  "trapRetractSpeed2",
  // "applicationsIdx",
  // "type",
  // "part",
  // "size",
  // "palatal",
  // "bottom",
];

export const validateProfile = ({
  profile,
}: {
  profile: Api.Request.AddPrintingProfile;
}) => {
  let fieldsToCheck = [...requiredFields];

  if (!profile.useBottomGrayOffset) {
    fieldsToCheck = fieldsToCheck.filter(
      (field) => field !== "bottomGrayOffset" && field !== "bottomGrayLevel",
    );
  }

  if (!profile.useTrapControl) {
    fieldsToCheck = fieldsToCheck.filter(
      (field) =>
        ![
          "trapLiftHeight1",
          "trapLiftHeight2",
          "trapRetractHeight1",
          "trapRetractHeight2",
          "trapLiftSpeed1",
          "trapLiftSpeed2",
          "trapRetractSpeed1",
          "trapRetractSpeed2",
        ].includes(field),
    );
  }

  switch (profile.waitingMode) {
    case "resting-time":
      fieldsToCheck = fieldsToCheck.filter(
        (field) => !["bottomLightOffDelay", "lightOffDelay"].includes(field),
      );
      break;
    case "light-off-delay":
      fieldsToCheck = fieldsToCheck.filter(
        (field) =>
          ![
            "restTimeBeforeLift",
            "restTimeAfterLift",
            "restTimeAfterRetract",
          ].includes(field),
      );
      break;
    default:
      break;
  }

  const missingFields = fieldsToCheck.filter((field) => {
    const value = profile[field as keyof Api.Request.AddPrintingProfile];
    return value === undefined || value === null;
  });

  return {
    isValid: missingFields.length === 0,
    missingFields,
  };
};
