import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import CustomSelectorTypes from "@/components/Common/CustomSelectorTypes";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { OptionProps } from "@/pages/AppEquipment";
import { handleDeleteProfile } from "./utils";
import { Config, Parameter, PARAMS } from "./utils/params";
import { validateProfile } from "./utils/validate";
import PageContainer from "../../components/Common/PageContainer";
import RenderOptions from "../../components/Profile/classificationOptions";
import CustomInputField from "../../components/Profile/CustomInput";
import NavField from "../../components/Profile/NavField";
import Selector from "../../components/Profile/Selector";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import usePrintingProfileStore from "../../zustandStore/printingProfilesStore";

const waitingModeOptions = [
  { label: "Resting time", value: "resting-time" },
  { label: "Light off delay", value: "light-off-delay" },
] as const;

/**
 * @type {Api.Response.PrintingProfile}
 */

export type ApplicationType = {
  applicationsIdx: number;
  imgPath: string;
};

type PrintingProfileFormTypes = {
  isDetailView: boolean;
};

export type ProfileProps = Api.Request.AddPrintingProfile &
  Partial<Pick<Api.Response.PrintingProfile, "printingIdx" | "defaultFlag">>;

const DEFAULT_PROFILE: ProfileProps = {
  profileName: "",
  thickVal: null,
  materialIdx: null,
  printerIdx: null,
  classIdx: null,
  displayFlag: 1,
  defaultFlag: 0,

  smoothingPixels: null,
  inlineTolerance: null,
  outlineTolerance: null,
  useAntiAliasing: true,
  useBottomGrayOffset: true,

  scaleX: null,
  scaleY: null,
  scaleZ: null,
  bottomGrayOffset: null,
  bottomGrayLevel: null,

  bottomLayerCount: null,
  transitionLayerCount: null,
  bottomLightPower: null,
  lightPower: null,

  bottomExposureTime: null,
  exposureTime: null,
  waitingMode: waitingModeOptions[0].value,
  bottomLightOffDelay: null,
  lightOffDelay: null,
  restTimeBeforeLift: null,
  restTimeAfterLift: null,
  restTimeAfterRetract: null,

  bottomLiftHeight1: null,
  bottomLiftHeight2: null,
  bottomRetractHeight1: null,
  bottomRetractHeight2: null,
  liftHeight1: null,
  liftHeight2: null,
  retractHeight1: null,
  retractHeight2: null,

  bottomLiftSpeed1: null,
  bottomLiftSpeed2: null,
  bottomRetractSpeed1: null,
  bottomRetractSpeed2: null,
  liftSpeed1: null,
  liftSpeed2: null,
  retractSpeed1: null,
  retractSpeed2: null,

  useTrapControl: true,
  trapLiftHeight1: null,
  trapLiftHeight2: null,
  trapRetractHeight1: null,
  trapRetractHeight2: null,
  trapLiftSpeed1: null,
  trapLiftSpeed2: null,
  trapRetractSpeed1: null,
  trapRetractSpeed2: null,

  applicationsIdx: 5,
  type: 0,
  part: 0,
  size: 0,
  palatal: 0,
  bottom: 0,
};

const PrintingProfileForm = ({ isDetailView }: PrintingProfileFormTypes) => {
  const { printingIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const { applications, getApplicationsTypes, loading, setLoading } =
    usePrintingProfileStore();

  const breadcrumbItems = [
    { title: "Printing Profile", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [printerOptions, setPrinterOptions] = useState<OptionProps[]>([]);
  const [materialOptions, setMaterialOptions] = useState<OptionProps[]>([]);
  const [classOptions, setClassOptions] = useState<OptionProps[]>([]);
  const [profile, setProfile] = useState(DEFAULT_PROFILE);
  const [selectedApplication, setSelectedApplication] = useState({
    applicationsIdx: profile.applicationsIdx,
    imgPath:
      applications.find(
        (app: ApplicationType) =>
          app.applicationsIdx === profile.applicationsIdx,
      )?.imgPath || "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [validationErrors, setValidationErrors] = useState({});

  const [optionsLoaded, setOptionsLoaded] = useState({
    printers: false,
    materials: false,
  });

  useEffect(() => {
    getApplicationsTypes();
  }, [getApplicationsTypes]);

  const getPrinters = async () => {
    try {
      const response = await Api.Common.getDefaultPrinterList();
      const options = response.data.map((item) => ({
        value: item.printerIdx,
        label: item.printerName,
      }));

      setPrinterOptions(options);

      setOptionsLoaded((prev) => ({ ...prev, printers: true }));
    } catch (error) {
      console.error("Error loading printers:", error);
    }
  };

  const getMaterials = async () => {
    try {
      const response = await Api.Common.getDefaultMaterialList();
      const options = response.data.map((item) => ({
        value: item.materialIdx,
        label: item.materialName,
      }));
      setMaterialOptions(options);
      setOptionsLoaded((prev) => ({ ...prev, materials: true }));
    } catch (error) {
      console.error("Error loading materials:", error);
    }
  };

  useEffect(() => {
    getPrinters();
    getMaterials();
  }, []);

  const fetchProfileByIdx = useCallback(async () => {
    if (!printingIdx) return;

    try {
      const response = await Api.PrintingProfile.get(parseInt(printingIdx, 10));
      const existingProfile = response.data;
      if (existingProfile) {
        setProfile(existingProfile);
        setSelectedApplication({
          applicationsIdx: existingProfile.applicationsIdx,
          imgPath: existingProfile.applicationsImgPath,
        });
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }, [printingIdx]);

  useEffect(() => {
    if (isDetailView) {
      fetchProfileByIdx();
    }
  }, [fetchProfileByIdx, isDetailView]);

  const getClasses = async () => {
    const response = await Api.Common.getClassList();

    setClassOptions(
      response.data.map((item) => ({
        value: item.classIdx,
        label: item.classCode,
      })),
    );
  };

  useEffect(() => {
    getPrinters();
    getMaterials();
    getClasses();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
    let newValue: string | number | boolean | null;

    if (type === "checkbox") {
      newValue = checked;
    } else if (value === "-1") {
      newValue = null;
    } else if (value === null || value === "") {
      newValue = "";
    } else if (
      name === "materialIdx" ||
      name === "printerIdx" ||
      name === "classIdx"
    ) {
      newValue = parseInt(value, 10);
    } else if (type === "number") {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }

    setProfile((prevProfile) => ({ ...prevProfile, [name]: newValue }));
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleApplicationTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedIdx = parseInt(e.target.value, 10);
    const selectedApp = applications.find(
      (app: Api.Response.CommonApplicationItem) =>
        app.applicationsIdx === selectedIdx,
    );

    setSelectedApplication({
      applicationsIdx: selectedIdx,
      imgPath: selectedApp?.imgPath || "",
    });
    setProfile((prevProfile) => ({
      ...prevProfile,
      applicationsIdx: selectedIdx,
    }));
  };

  const handleValidationError = (id: string, errorMessage: string) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const handleSaveProfile = async () => {
    const { isValid } = validateProfile({ profile });

    if (!isValid) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    if (Object.values(validationErrors).some((error) => error)) {
      toast.error("저장하시기 전에 양식의 오류를 수정해 주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      const updatedProfile = {
        profileName: profile.profileName,
        thickVal: profile.thickVal,
        materialIdx: profile.materialIdx,
        printerIdx: profile.printerIdx,
        classIdx: profile.classIdx,
        displayFlag: profile.displayFlag,

        smoothingPixels: profile.smoothingPixels,
        inlineTolerance: profile.inlineTolerance,
        outlineTolerance: profile.outlineTolerance,
        useAntiAliasing: profile.useAntiAliasing ?? true,
        useBottomGrayOffset: profile.useBottomGrayOffset ?? true,

        scaleX: profile.scaleX,
        scaleY: profile.scaleY,
        scaleZ: profile.scaleZ,
        bottomGrayOffset: profile.bottomGrayOffset ?? 0,
        bottomGrayLevel: profile.bottomGrayLevel ?? 0,

        bottomLayerCount: profile.bottomLayerCount,
        transitionLayerCount: profile.transitionLayerCount,
        bottomLightPower: profile.bottomLightPower,
        lightPower: profile.lightPower,

        bottomExposureTime: profile.bottomExposureTime,
        exposureTime: profile.exposureTime,
        waitingMode: profile.waitingMode ?? waitingModeOptions[0].value,
        bottomLightOffDelay: profile.bottomLightOffDelay ?? 0,
        lightOffDelay: profile.lightOffDelay ?? 0,
        restTimeBeforeLift: profile.restTimeBeforeLift ?? 0,
        restTimeAfterLift: profile.restTimeAfterLift ?? 0,
        restTimeAfterRetract: profile.restTimeAfterRetract ?? 0,

        bottomLiftHeight1: profile.bottomLiftHeight1,
        bottomLiftHeight2: profile.bottomLiftHeight2,
        bottomRetractHeight1: profile.bottomRetractHeight1,
        bottomRetractHeight2: profile.bottomRetractHeight2,
        liftHeight1: profile.liftHeight1,
        liftHeight2: profile.liftHeight2,
        retractHeight1: profile.retractHeight1,
        retractHeight2: profile.retractHeight2,
        bottomLiftSpeed1: profile.bottomLiftSpeed1,
        bottomLiftSpeed2: profile.bottomLiftSpeed2,
        bottomRetractSpeed1: profile.bottomRetractSpeed1,
        bottomRetractSpeed2: profile.bottomRetractSpeed2,
        liftSpeed1: profile.liftSpeed1,
        liftSpeed2: profile.liftSpeed2,
        retractSpeed1: profile.retractSpeed1,
        retractSpeed2: profile.retractSpeed2,

        useTrapControl: profile.useTrapControl ?? true,
        trapLiftHeight1: profile.trapLiftHeight1 ?? 0,
        trapLiftHeight2: profile.trapLiftHeight2 ?? 0,
        trapRetractHeight1: profile.trapRetractHeight1 ?? 0,
        trapRetractHeight2: profile.trapRetractHeight2 ?? 0,
        trapLiftSpeed1: profile.trapLiftSpeed1 ?? 0,
        trapLiftSpeed2: profile.trapLiftSpeed2 ?? 0,
        trapRetractSpeed1: profile.trapRetractSpeed1 ?? 0,
        trapRetractSpeed2: profile.trapRetractSpeed2 ?? 0,

        applicationsIdx: profile.applicationsIdx,
        type: profile.type ?? 0,
        part: profile.part ?? 0,
        size: profile.size ?? 0,
        palatal: profile.palatal ?? 0,
        bottom: profile.bottom ?? 0,
      };

      let response;
      if (isDetailView) {
        if (!printingIdx) return;
        response = await Api.PrintingProfile.update({
          ...updatedProfile,
          printingIdx: parseInt(printingIdx, 10),
        });
      } else {
        response = await Api.PrintingProfile.add(updatedProfile);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/printing-profile${location.search}`);
        } else {
          navigate("/printing-profile");
        }
      }, 1000);
    } catch (error) {
      console.error("Error: adding new profile", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyProfile = async () => {
    setLoading(true);
    if (!printingIdx) return;

    try {
      const response = await Api.PrintingProfile.copy(
        parseInt(printingIdx, 10),
      );
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/printing-profile");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (!printingIdx) return;
    let idx = parseInt(printingIdx, 10);
    handleDeleteProfile({
      printingIdx: idx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
  };

  const handleCancel = () => {
    navigate(`/printing-profile${location.search}`);
  };

  useEffect(() => {
    if (!profile.useBottomGrayOffset) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        bottomGrayOffset: null,
        bottomGrayLevel: null,
      }));
    }
  }, [profile.useBottomGrayOffset]);

  useEffect(() => {
    if (!profile.useTrapControl) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        trapLiftHeight1: null,
        trapLiftHeight2: null,
        trapRetractHeight1: null,
        trapRetractHeight2: null,
        trapLiftSpeed1: null,
        trapLiftSpeed2: null,
        trapRetractSpeed1: null,
        trapRetractSpeed2: null,
      }));
    }
  }, [profile.useTrapControl]);

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Printing Profile">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <AvForm>
                <NavField title="기본설정">
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    showCopyButton
                  />
                </NavField>

                <Row>
                  <Col className="col-12 col-md-6">
                    <FormInputTypes
                      id="profileName"
                      label="프로파일명"
                      placeholder="프로파일명을 입력해주세요"
                      type="text"
                      value={profile.profileName}
                      onChange={handleChange}
                      initialErrorMessage="프로파일명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      isRequired
                    />
                  </Col>
                  <Col className="col-12 col-md-6">
                    <FormInputTypes
                      id="thickVal"
                      label="두께(μm)"
                      placeholder="두께를 입력해주세요"
                      type="number"
                      value={profile.thickVal || ""}
                      onChange={handleChange}
                      initialErrorMessage="두께를 입력해주세요"
                      validate={{ required: { value: true } }}
                      isRequired
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <div>
                      <CustomLabel
                        htmlFor="materialIdx"
                        title="소재"
                        isRequired
                      />
                      <CustomSelectorTypes
                        placeholder="Select Material"
                        name="materialIdx"
                        value={profile.materialIdx || ""}
                        options={materialOptions}
                        isLoading={!optionsLoaded.materials}
                        isDetailView={isDetailView}
                        onChange={handleChange}
                        onSelectDefaultOption={(value) => {
                          if (!isDetailView) {
                            setProfile((prev) => ({
                              ...prev,
                              materialIdx:
                                typeof value === "number" ? value : -1,
                            }));
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <div>
                      <CustomLabel
                        htmlFor="printerIdx"
                        title="프린터"
                        isRequired
                      />
                      <CustomSelectorTypes
                        placeholder="Select Printer"
                        name="printerIdx"
                        value={profile.printerIdx || ""}
                        options={printerOptions}
                        isLoading={!optionsLoaded.printers}
                        isDetailView={isDetailView}
                        onChange={handleChange}
                        onSelectDefaultOption={(value) => {
                          if (!isDetailView) {
                            setProfile((prev) => ({
                              ...prev,
                              printerIdx:
                                typeof value === "number" ? value : -1,
                            }));
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <div>
                      <CustomLabel
                        htmlFor="classIdx"
                        title="분류유형"
                        isRequired
                      />
                      <CustomSelectorTypes
                        placeholder="Select Class"
                        name="classIdx"
                        value={profile.classIdx || ""}
                        options={classOptions}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3">
                    <ToggleSwitch
                      isFullWidthLabel
                      label="표시여부"
                      id="displayFlag"
                      checked={profile.displayFlag === 1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProfile({ ...profile, displayFlag: 1 });
                        } else {
                          setProfile({ ...profile, displayFlag: 0 });
                        }
                      }}
                    />
                  </Col>
                  {isDetailView && (
                    <Col>
                      <ToggleSwitch
                        isFullWidthLabel
                        label="기본프로파일여부"
                        id="defaultFlag"
                        checked={profile.defaultFlag === 1}
                        disabled={true}
                        style={{ opacity: "0.5" }}
                      />
                    </Col>
                  )}
                </Row>
                <NavField title="상세설정" />
                <Row>
                  <Col className="col-12 col-md-6">
                    {PARAMS[0]?.params1?.map((detail) => {
                      return detail.type === "number" ? (
                        <Col key={detail.name} className="mb-3">
                          <CustomInputField
                            min={detail.min}
                            max={detail.max}
                            step={detail.decimal}
                            label={detail.label}
                            unit={detail.unit}
                            id={detail.name}
                            type={detail.type}
                            value={profile[detail.name as keyof ProfileProps]}
                            onChange={handleChange}
                            placeholder={detail.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </Col>
                      ) : (
                        <Col className="mt-3" key={detail.name}>
                          <ToggleSwitch
                            label={detail.label}
                            id={detail.name}
                            checked={
                              Boolean(
                                profile[detail.name as keyof ProfileProps],
                              ) || false
                            }
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                [detail.name]: e.target.checked,
                              })
                            }
                            isFullWidthLabel
                          />
                        </Col>
                      );
                    })}
                  </Col>

                  <Col className="col-12 col-md-6">
                    {PARAMS[0]?.params2?.map((detail) => {
                      const isDisabled =
                        !profile.useBottomGrayOffset &&
                        (detail.name === "bottomGrayOffset" ||
                          detail.name === "bottomGrayLevel");

                      return (
                        <Col key={detail.name} className="mb-3">
                          <CustomInputField
                            min={detail.min}
                            max={detail.max}
                            step={detail.decimal}
                            label={detail.label}
                            unit={detail.unit}
                            id={detail.name}
                            type={detail.type}
                            value={profile[detail.name as keyof ProfileProps]}
                            onChange={handleChange}
                            placeholder={detail.placeholder}
                            onValidationError={handleValidationError}
                            disabled={isDisabled}
                            isRequired={!isDisabled}
                          />
                        </Col>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <NavField title="조건설정" />
                    {PARAMS[1]?.params?.map((condition) => {
                      const max =
                        typeof condition.max === "function"
                          ? condition.max(profile)
                          : condition.max;

                      return (
                        <Col key={condition.name} className="mb-3">
                          <CustomInputField
                            min={condition.min}
                            max={max}
                            step={condition.decimal}
                            label={condition.label}
                            unit={condition.unit}
                            id={condition.name}
                            type={condition.type}
                            value={
                              profile[condition.name as keyof ProfileProps]
                            }
                            onChange={handleChange}
                            placeholder={condition.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </Col>
                      );
                    })}
                  </Col>
                  <Col className="col-12 col-md-6">
                    <NavField title="시간설정" />
                    {PARAMS[2]?.params?.map((time) => {
                      return time.type === "number" ? (
                        <Col key={time.name} className="mb-3">
                          <CustomInputField
                            min={time.min}
                            max={time.max}
                            step={time.decimal}
                            label={time.label}
                            unit={time.unit}
                            id={time.name}
                            type={time.type}
                            value={profile[time.name as keyof ProfileProps]}
                            onChange={handleChange}
                            placeholder={time.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </Col>
                      ) : (
                        <Col className="mb-3" key={time.name}>
                          <Selector
                            label="대기모드"
                            id="waitingMode"
                            options={waitingModeOptions}
                            value={profile.waitingMode}
                            onChange={handleChange}
                            colMd={12}
                          />
                        </Col>
                      );
                    })}
                    {Array.isArray(
                      PARAMS[2][profile.waitingMode as keyof Config],
                    ) &&
                      (
                        PARAMS[2][
                          profile.waitingMode as keyof Config
                        ] as Parameter[]
                      ).map((time) => (
                        <Col key={time.name} className="mb-3">
                          <CustomInputField
                            min={time.min}
                            max={time.max}
                            step={time.decimal}
                            label={time.label}
                            unit={time.unit}
                            id={time.name}
                            type={time.type}
                            value={profile[time.name as keyof ProfileProps]}
                            onChange={handleChange}
                            placeholder={time.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </Col>
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-md-6">
                    <NavField title="거리설정" />
                    {PARAMS[3]?.params?.map((height) => (
                      <Col key={height.name} className="mb-3">
                        <CustomInputField
                          min={height.min}
                          max={height.max}
                          step={height.decimal}
                          label={height.label}
                          unit={height.unit}
                          id={height.name}
                          type={height.type}
                          value={profile[height.name as keyof ProfileProps]}
                          onChange={handleChange}
                          placeholder={height.placeholder}
                          onValidationError={handleValidationError}
                          isRequired
                        />
                      </Col>
                    ))}
                  </Col>

                  <Col className="col-12 col-md-6">
                    <NavField title="속도설정" />
                    {PARAMS[4]?.params?.map((speed) => (
                      <Col key={speed.name} className="mb-3">
                        <CustomInputField
                          min={speed.min}
                          max={speed.max}
                          step={speed.decimal}
                          label={speed.label}
                          unit={speed.unit}
                          id={speed.name}
                          type={speed.type}
                          value={profile[speed.name as keyof ProfileProps]}
                          onChange={handleChange}
                          placeholder={speed.placeholder}
                          onValidationError={handleValidationError}
                          isRequired
                        />
                      </Col>
                    ))}
                  </Col>
                </Row>
                <NavField title="트랩설정" />
                <Row>
                  {PARAMS[5]?.params1?.map((height) => {
                    return (
                      height.type === "boolean" && (
                        <Row key={height.name} className="mb-3">
                          <ToggleSwitch
                            label={height.label}
                            id={height.name}
                            checked={
                              Boolean(
                                profile[height.name as keyof ProfileProps],
                              ) || false
                            }
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                [height.name]: e.target.checked,
                              })
                            }
                            isFullWidthLabel
                          />
                        </Row>
                      )
                    );
                  })}
                  <Col className="col-12 col-md-6">
                    {PARAMS[5]?.params1?.map((height) => {
                      return (
                        height.type === "number" && (
                          <Col key={height.name} className="mb-3">
                            <CustomInputField
                              min={height.min}
                              max={height.max}
                              step={height.decimal}
                              label={height.label}
                              unit={height.unit}
                              id={height.name}
                              type={height.type}
                              value={profile[height.name as keyof ProfileProps]}
                              onChange={handleChange}
                              placeholder={height.placeholder}
                              onValidationError={handleValidationError}
                              disabled={!profile.useTrapControl}
                              isRequired={
                                !profile.useTrapControl ? false : true
                              }
                            />
                          </Col>
                        )
                      );
                    })}
                  </Col>
                  <Col className="col-12 col-md-6">
                    {PARAMS[5]?.params2?.map((speed) => (
                      <Col key={speed.name} className="mb-3">
                        <CustomInputField
                          min={speed.min}
                          max={speed.max}
                          step={speed.decimal}
                          label={speed.label}
                          unit={speed.unit}
                          id={speed.name}
                          type={speed.type}
                          value={profile[speed.name as keyof ProfileProps]}
                          onChange={handleChange}
                          placeholder={speed.placeholder}
                          onValidationError={handleValidationError}
                          disabled={!profile.useTrapControl}
                          isRequired={!profile.useTrapControl ? false : true}
                        />
                      </Col>
                    ))}
                  </Col>
                </Row>
                <NavField title="Classification" />
                <Row className="mb-3" style={{ display: "flex", gap: "70px" }}>
                  <Col xl="2">
                    <img
                      src={selectedApplication.imgPath}
                      alt={`application-${profile.applicationsIdx}`}
                      className="rounded avatar-lg"
                      style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col xl="2" className="mb-4">
                    <Label className="form-label h6">Applications</Label>
                    {applications.map(
                      (type: Api.Response.CommonApplicationItem) => (
                        <div
                          className="form-check mb-2"
                          key={type.applicationsIdx}
                        >
                          <Input
                            type="radio"
                            id={`application-${type.applicationsIdx}`}
                            name="applicationsIdx"
                            className="form-check-input"
                            value={type.applicationsIdx}
                            checked={
                              selectedApplication.applicationsIdx ===
                              type.applicationsIdx
                            }
                            onChange={handleApplicationTypeChange}
                          />
                          <Label
                            htmlFor={`application-${type.applicationsIdx}`}
                            className="form-check-label"
                          >
                            {type.name}
                          </Label>
                        </div>
                      ),
                    )}
                  </Col>
                  <Col>
                    <RenderOptions
                      profile={profile}
                      selectedApplication={selectedApplication}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  showCopyButton
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveProfile}
                  handleDelete={handleDelete}
                  handleCopy={handleCopyProfile}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default PrintingProfileForm;
