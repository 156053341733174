import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomFileInputTypes from "@/components/Common/CustomFileInputTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import CustomSelector from "@/components/Common/CustomSelector";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { OptionProps } from "@/pages/AppEquipment";
import { handleDeleteMaterial } from "./utils";
import PageContainer from "../../components/Common/PageContainer";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import useMaterialStore from "../../zustandStore";
import { CardImgContainer, HoverImage, RowContainer } from "../Utility/styled";

type MaterialFormTypes = {
  isDetailView: boolean;
};

type MaterialProps = Api.Request.AddMaterial &
  Partial<
    Pick<Api.Response.Material, "materialIdx" | "defaultFlag" | "imgPath">
  >;

const DEFAULT_MATERIAL: MaterialProps = {
  materialName: "",
  mmIdx: null,
  displayFlag: 1,
  file: null,
  defaultFlag: 0,
};

const MaterialForm = ({ isDetailView }: MaterialFormTypes) => {
  const navigate = useNavigate();
  const { materialIdx } = useParams();
  const { loading, setLoading } = useMaterialStore();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Material Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [material, setMaterial] = useState(DEFAULT_MATERIAL);
  const [manufOptions, setManufOptions] = useState<OptionProps[]>([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [fileName, setFileName] = useState(material.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const getMaterialByIdx = useCallback(async () => {
    if (!materialIdx) return;
    try {
      const response = await Api.Material.get(parseInt(materialIdx, 10));
      const existingMaterial = response.data;
      if (existingMaterial) {
        setMaterial(existingMaterial);
      } else {
        setMaterial({
          materialName: "",
          displayFlag: 1,
          file: null,
        });
      }
    } catch (error) {
      console.error("Error fetching Material from API", error);
    }
  }, [materialIdx]);

  useEffect(() => {
    if (isDetailView) {
      getMaterialByIdx();
    }
  }, [getMaterialByIdx, isDetailView]);

  useEffect(() => {
    const getMaterialManufacturers = async () => {
      const response = await Api.Common.getMaterialManufList();
      const manufacturers = response?.data || [];
      if (manufacturers.length === 0) return;

      const options = manufacturers.map((item) => ({
        value: item.mmIdx,
        label: item.name,
      }));
      setManufOptions(options);
      setOptionsLoaded(true);

      if (!isDetailView && !material.mmIdx) {
        setMaterial((prev) => ({ ...prev, mmIdx: manufacturers[0].mmIdx }));
      }
    };
    getMaterialManufacturers();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target as
      | HTMLInputElement
      | HTMLSelectElement;
    const checked = (e.target as HTMLInputElement).checked;
    const files = (e.target as HTMLInputElement).files;

    let newValue: string | boolean | File | number | null =
      type === "checkbox" ? checked : files ? files[0] : value;

    if (name === "mmIdx") {
      newValue = Number(value);
    } else if (name === "file" && newValue instanceof File) {
      setFileName(newValue.name);
    }

    setMaterial((prevMaterial) => ({
      ...prevMaterial,
      [name]: newValue,
    }));
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveMaterial = async () => {
    if (!material.materialName || !material.mmIdx || material.mmIdx <= 0) {
      toast.error("소재명과 제조사명을 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }

    try {
      setLoading(true);
      let response;
      const requestData = {
        materialName: material.materialName,
        mmIdx: material.mmIdx,
        displayFlag: material.displayFlag,
        file: material.file,
      };
      if (isDetailView) {
        if (typeof material?.materialIdx !== "number") return;
        response = await Api.Material.update({
          ...requestData,
          materialIdx: material.materialIdx,
        });
      } else {
        response = await Api.Material.add(requestData);
      }
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/material${location.search}`);
        } else {
          navigate("/material");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (!material.materialIdx) return;
    const materialIdx = material.materialIdx;
    handleDeleteMaterial({
      materialIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
  };

  const handleCancel = () => {
    navigate(`/material${location.search}`);
  };

  return (
    <PageContainer
      breadcrumbItems={breadcrumbItems}
      title="Material Management"
    >
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">소재 정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="materialName"
                    label="소재명"
                    placeholder="소재명을 입력해주세요"
                    type="text"
                    value={material.materialName}
                    onChange={handleChange}
                    initialErrorMessage="소재명을 입력해주세요"
                    validate={{ required: { value: true } }}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="mmIdx" title="제조사명" isRequired />
                  <div className="col-md-10">
                    <CustomSelector
                      placeholder="Select Material"
                      name="mmIdx"
                      value={material.mmIdx}
                      options={manufOptions}
                      isLoading={!optionsLoaded}
                      isDetailView={isDetailView}
                      onChange={handleChange}
                      onSelectDefaultOption={(value: number) => {
                        if (!isDetailView) {
                          setMaterial((prev) => ({
                            ...prev,
                            mmIdx: value,
                          }));
                        }
                      }}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <CustomLabel htmlFor="customFileInput" title="이미지" />
                  <CustomFileInputTypes
                    fileName={fileName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !material.file && material.imgPath && (
                  <RowContainer className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        src={material.imgPath}
                        alt=""
                        className="avatar-lg"
                      />
                      <HoverImage
                        style={{
                          backgroundImage: `url(${material.imgPath})`,
                        }}
                      />
                    </Col>
                  </RowContainer>
                )}

                <Row className="mb-2">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={material.displayFlag === 1 ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setMaterial({ ...material, displayFlag: 1 });
                      } else {
                        setMaterial({ ...material, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                {isDetailView && (
                  <Row className="mb-2">
                    <ToggleSwitch
                      label="기본소재여부"
                      id="defaultFlag"
                      checked={material.defaultFlag === 1}
                      extraClass="mt-2"
                      style={{ opacity: "0.5" }}
                      disabled
                    />
                  </Row>
                )}
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveMaterial}
                  handleDelete={handleDelete}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default MaterialForm;
